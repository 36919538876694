/**
 * @module
 * map widget
 */

import OLMap from 'ol/Map.js';
import View from 'ol/View.js';
import {ScaleLine, defaults as defaultControls} from 'ol/control.js';
import Overlay from 'ol/Overlay.js';
import Feature from 'ol/Feature.js';
import OSM from 'ol/source/OSM.js';
import {Vector as VectorSource} from 'ol/source.js';
import Vector from 'ol/source/Vector.js';
import GeoJSON from 'ol/format/GeoJSON.js';
import Geolocation from 'ol/Geolocation.js';
import Point from 'ol/geom/Point.js';
import TileLayer from 'ol/layer/Tile.js';
import TileGrid from 'ol/tilegrid/TileGrid.js';
import VectorLayer from 'ol/layer/Vector.js';
import {bbox, tile} from 'ol/loadingstrategy';
import {fromLonLat, transformExtent} from 'ol/proj';
import {
  Circle as CircleStyle,
  Fill,
  Icon,
  Stroke,
  Style,
  Text,
} from 'ol/style.js';
import $ from 'jquery';

import {_} from './locale.js';
import {Widget} from "./widget.js";
import {poisDB} from './pois.js';

// ---- classes ----

/**
 * map widget
 */
export class Map extends Widget{
    
    constructor(query, status, details, mainDialog){
	super();
	var self = this;

	// references to application objects
	this.query = query;
	this.status = status;
	this.details = details;
	this.mainDialog = mainDialog;
	
	// display limit constants
	this.POI_MIN_ZOOM = 8.5;
	this.POI_MAX_ZOOM = 17.5;
	
	var self = this;

	// start hidden
	$('#map').hide();

	// basic map + controls
	this.olMap = new OLMap({
	    target: 'map',
	    layers: [
		new TileLayer({
		    source: new OSM(),
		}),
	    ],
	    view: new View({
		center: fromLonLat([18, 60]),
		constrainResolution: true,
		maxZoom: 19,
		zoom: 10,
	    }),
	});

	var control = new ScaleLine({
	    units: "metric",
	    bar: true,
	    text: true,
	    minWidth: 150,
	});
	this.olMap.addControl(control);

	// basic POI layer (without clustering)
	this.poiRequests = new Set();
	var poiGrid = new TileGrid({
	    minZoom:8,
	    maxZoom:8,
	    origin:[0,0],
	    tileSize:[512,512],
	    resolutions:[305.748113140705],
	});
	this.poiSource = new Vector({
	    loader: (...args)=>self.loadPOIs(...args),
	    strategy: tile(poiGrid),
	    projection: 'EPSG:4326',
	    overlaps: false,
	});
	var poiLayer=new VectorLayer({
	    source: this.poiSource,
	    maxZoom:this.POI_MAX_ZOOM,
	    minZoom: this.POI_MIN_ZOOM,
	    style:(...args)=>this.poiStyle(...args),
	});
	this.olMap.addLayer(poiLayer);

	// popup
	this.olMap.on('pointermove', function (e) {
	    const pixel = self.olMap.getEventPixel(e.originalEvent);
	    const hit = self.olMap.hasFeatureAtPixel(pixel);
	    $("#map").css("cursor",hit ? 'pointer' : '');
	});
	this.olMap.on('click', function (e) {
	    const pixel = self.olMap.getEventPixel(e.originalEvent);

	    var poi = null;
	    self.olMap.forEachFeatureAtPixel(pixel, (f)=>{
		var p = f.get('poi');
		if(p){
		    poi = p;
		}
	    });
	    if(poi){
		e.preventDefault();
		details.show(poi);
	    }
	});
	
	// show message if zoomed out too much
	this.prevZoom = this.olMap.getView().getZoom();
	this.olMap.on('moveend', (e)=>{
	    this.checkZoomWarning();
	    var zoom = this.olMap.getView().getZoom();
	    if (this.prevZoom >= this.POI_MIN_ZOOM && zoom < this.POI_MIN_ZOOM){
		this.refresh();
	    }
	    this.prevZoom = zoom;
	});
	
	// register dependency on query
	this.query.registerChangedCallback(this,(source)=>{
	    if(this.query.categories.size){
		this.checkZoomWarning();
	    }
	    self.refresh();
	});

	// geolocation
	this.geolocation = new Geolocation({
	    trackingOptions: {
		enableHighAccuracy: true,
	    },
	    projection: this.olMap.getView().getProjection(),
	});
	this.geolocation_current = false;

	this.geolocation.on('error', function (error) {
	    self.status.set(error.message);
	});

	this.geolocation.on('change:position', ()=> {
	    if(!this.geolocation_current){
		this.geolocation_current = true;
		this.status.set(_("found position"));
	    }
	    const coordinates = this.geolocation.getPosition();
	    if(coordinates){
		this.olMap.getView().setCenter(coordinates);
	    }
	});

	
	const accuracyFeature = new Feature();
	this.geolocation.on('change:accuracyGeometry', function () {
	    accuracyFeature.setGeometry(self.geolocation.getAccuracyGeometry());
	});

	const positionFeature = new Feature();
	positionFeature.setStyle(
	    new Style({
		image: new CircleStyle({
		    radius: 6,
		    fill: new Fill({
			color: '#FF0000',
		    }),
		    stroke: new Stroke({
			color: '#fff',
			width: 2,
		    }),
		}),
	    }),
	);
	
	this.geolocation.on('change:position', function () {
	    const coordinates = self.geolocation.getPosition();
	    positionFeature.setGeometry(coordinates ? new Point(coordinates) : null);
	});

	this.locationLayer = new VectorLayer({
	    source: new VectorSource({
		features: [accuracyFeature, positionFeature],
	    }),
	});
	this.olMap.addLayer(this.locationLayer);
	
	// uppdatera om redo (krävs för widget)
	this.updateIfReady();
    }

    update(){
	// show map when widget is ready for interaction
	$('#map').show();
	
    }

    // ---- general properties ----
    
    /**
     * @return extent of map
     */
    get extent(){
	return this.olMap.getView().calculateExtent(this.olMap.getSize());
    }

    refresh(){
	for(var request of this.poiRequests){
	    request.abort();
	    this.poiRequests.delete(request);
	}
	this.poiSource.refresh();
    }

    panPixels(dx,dy){
	var center = this.olMap.getView().getCenter();
	var resolution = this.olMap.getView().getResolution();
	this.olMap.getView().setCenter([center[0] + dx*resolution, center[1] - dy*resolution]);
    }

    // ---- POI layer ----

    /**
     * @return style for POIs
     */
    poiStyle(feature, resolution){
	var poi = feature.get("poi");

	return new Style({
	    image: new Icon({
		anchorXUnits: 'fraction',
		anchorYUnits: 'fraction',
		width:32,
		src: 'images/' + poi.category.id + '.svg',
	    }),
	});
    }

    /**
     * @effect async load POI features from extent into poiSource 
     */
    loadPOIs(extent, resolution, projection){
	// effekt: ladda detaljer på nivå level
	// till karta från extent, i resolution och projection
	
	var ex=transformExtent(extent,projection,'EPSG:4326');
	if(this.query.categories.size){
	    var request = poisDB.lookup(this.query, ex, (request, pois)=>{
		// make features from POIs
		var features = [];
		for(var poi of pois){
		    features.push(new Feature({
			id: poi.id,
			geometry: new Point(fromLonLat(poi.location)),
			poi: poi,
		    }));
		}
		this.poiRequests.delete(request);
		this.updateStatus();
		
		// add features
		this.poiSource.addFeatures(features);
	    },(request)=>{
		this.status.set(_("lookup-rate-warning"), 5000);
	    },(request)=>{
		this.status.set(_("lookup-error"), 5000);
	    });
	    this.poiRequests.add(request);
	}

	this.updateStatus();
    }

    /**
     * @effect: show warning if zoomed out so POI:s are not visible
     */
    checkZoomWarning(){
	var zoom = this.olMap.getView().getZoom();
	if(zoom < this.POI_MIN_ZOOM){
	    this.status.set(_("zoom too small for poi"),5000,'zoomWarning');
	}
	else if(zoom > this.POI_MAX_ZOOM){
	    this.status.set(_("zoom too large for poi"),5000,'zoomWarning');
	}
	else{
	    if(this.status.type == 'zoomWarning'){
		this.status.set(null);
	    }
	}
    }
    
    /**
     * @effect: sets application status message based on requests
     */ 
    updateStatus(){
	if(this.poiRequests.size > 0){
	    this.status.set(_("reading results ({{nr}})",{nr:this.poiRequests.size}),30000,"results");
	}
	else{
	    if(this.query.categories.size){
		this.status.set(_("results ready"),2000,"results");
	    }
	    else{
		if(this.status.type == 'results'){
		    this.status.set(null);
		}
	    }
	}
    }

    // geolocation
    /**
     * @effect: start zoom to current location
     */ 
    startGeoLocation(){
	this.status.set(_("getting current location"), 5000);
	this.geolocation.setTracking(true);
	this.locationLayer.setVisible(true);
    }
    
    /**
     * @effect: stops zoom to current location
     */ 
    stopGeoLocation(){
	this.geolocation_current = false;
	this.geolocation.setTracking(false);
	this.locationLayer.setVisible(false);
    }
}
